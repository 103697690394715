<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Peta <b>Sebaran Pasien</b> yang ada di Klinik Hayandra
      </div>
    </b-alert>

    <!-- Component List -->
    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <DistributionMapGraphic />
          </template>
        </Card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="row justify-content-between">
              <div class="col-6">
                <div class="row">
                  <div class="col-md-6">
                    <b-input-group>
                      <treeselect
                        v-model="filter.province_id"
                        :options="region"
                        placeholder="Pilih Provinsi"
                        @input="regionSet"
                        required
                      />
                    </b-input-group>
                  </div>
                  <div class="col-md-6" v-if="isRegion">
                    <b-input-group>
                      <treeselect
                        v-model="filter.capital_id"
                        :options="regionChild"
                        placeholder="Pilih Kota/Kabupaten"
                        required
                        @input="filterByCapital"
                      />
                    </b-input-group>
                  </div>
                </div>
              </div>
              <div class="col text-right">
                <vue-excel-xlsx
                  :data="items"
                  :columns="columns"
                  :file-name="'peta-sebaran-pasien'"
                  :file-type="'xlsx'"
                  :sheet-name="'sheet 1'"
                >
                  <b-button class="sm mr-2" style="background-color: #016e38"><i class="fas fa-file-excel px-0" style="color: #ffffff"></i></b-button>
                </vue-excel-xlsx>
                  <b-button class="sm" variant="primary" @click="btnPrintOnClick"><i class="fas fa-print px-0" style="color: #ffffff"></i></b-button>
              </div>
            </div>

            <b-table
              striped
              hover
              class="mt-3"
              :items="items"
              :fields="fields"
              :style="'white-space: nowrap'"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '15%' : '' }"
                />
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="
                    $router.push({ path: '/coas/detail/' + data.item.id })
                  "
                  ><i class="fas fa-eye px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  @click="$router.push({ path: '/coas/edit/' + data.item.id })"
                  v-if="btn"
                  ><i class="fas fa-edit px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  @click="deleteData(data.item.id)"
                  v-if="btn"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>

    <DistributionMapPrint :items="items" id="printMe" class="d-none"/>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import saasModule from "@/core/modules/CrudSaasModule.js";
import DistributionMapGraphic from "@/component/patients/DistributionMapGraphic.vue";
import DistributionMapPrint from "@/component/patients/DistributionMapPrint.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "List",

  components: {
    Card,
    DistributionMapGraphic,
    DistributionMapPrint,
  },

  data() {
    return {
      // Filter
      filter: {
        province_id: null,
        capital_id: null,
      },
      coa_categories: [],
      sub_ledgers: [],
      cash_flows: [],
      // Pagination
      perPage: 35,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "list_number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Wilayah",
          sortable: true,
        },
        {
          key: "total_patient",
          label: "Jumlah Pasien",
          sortable: true,
        },
        {
          key: "total_male",
          label: "Laki-laki",
          sortable: true,
        },
        {
          key: "total_female",
          label: "Perempuan",
          sortable: true,
        },
      ],
      columns: [
        {
          field: "name",
          label: "Wilayah",
        },
        {
          field: "total_patient",
          label: "Jumlah Pasien",
        },
        {
          field: "total_male",
          label: "Laki-laki",
        },
        {
          field: "total_female",
          label: "Perempuan",
        },
      ],
      // Table Items
      items: [],
      region: [],
      regionChild: [],
      isRegion: false,
      // User Access
      btn: false,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&province_id=${this.filter.province_id}&capital_id=${this.filter.capital_id}`;
      let response = await module.paginateCoa(
        `patients/distribution-map/table`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].list_number = b + a;
      }
      this.items = response.data;
      console.log("items", this.items);
    },

    regionSet(evt) {
      if (typeof evt == "undefined") {
        this.filter.province_id = null;
        console.log("evt", evt);
        console.log("filter", this.filter);
      }
      let status = this.getRegionChildOptions(evt);
      this.pagination();

      if (status) {
        this.isRegion = true;
      }
    },

    filterByCapital(evt) {
      if (typeof evt == "undefined") {
        this.filter.capital_id = null;
      }
      this.pagination();
    },

    async getRegionOptions() {
      let response = await module.saasModule("regions");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.region = response.data;
      }

      if (this.filter.province_id != null) {
        this.isRegion = true;
        this.getRegionChildOptions(this.filter.province_id);
      }
    },

    async getRegionChildOptions(id) {
      let response = await module.saasModule("region/" + id);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.regionChild = response.data;
      }

      return true;
    },

    async btnPrintOnClick(){
      await this.$htmlToPaper('printMe');
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6010") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien" },
      { title: "Peta Sebaran" },
    ]);
    // Get Data
    this.pagination();
    this.setActiveButton();
    this.getRegionOptions();
  },
};
</script>
